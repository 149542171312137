<template>
  <div id="single-site-dashboard" class="site-container">
    <div v-if="isLoading" class="loading-screen">
      <div class="loading-container">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <template v-else>
      <div v-if="dataSingleSite" class="wp-single-site">
        <div class="single-site-general">
          <div class="col-12">
            <div class="row">
              <div class="col-4 customize-col-4 customize--left">
                <div class="site-information-form">
                  <div class="site-information-content">
                    <div class="site-information-detail">
                      <div class="detail-desc">
                        <div class="row-info-space">
                          <p v-if="dataSingleSite" class="project-address">{{ dataSingleSite.global }} / {{ dataSingleSite.regional }} / {{ dataSingleSite.area }} / {{ dataSingleSite.project }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">SITE ID</p>
                          <p class="detail-desc-content">{{ dataSingleSite.id ? dataSingleSite.id : '' }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">SITE NAME</p>
                          <p class="detail-desc-content">{{ dataSingleSite.site_name ? dataSingleSite.site_name : '' }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">COMMISSIONED DATE</p>
                          <p class="detail-desc-content">{{ dataSingleSite.commissioned_date ? MOMENT(dataSingleSite.commissioned_date, "DD/MM/YYYY") : '' }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="site-information-weather">
                  <div class="information-weather-wrapper">
                    <VueWeatherComponent title="Weather" units="ca" :currentDay="currentDay" :forecast="forecast" :enable-credits="false">
                    </VueWeatherComponent>
                    <div class="weather-status-today">
                      <p class="high-status">{{ currentDay && currentDay.description ? capitalizeFirstLetter(currentDay.description) : '' }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8 customize-col-8 customize--right">
                <div class="information-boxes-wrapper">
                  <div class="information-box-heading">
                    <h2 class="information-box-heading-title">COST & SAVING SUMMARY</h2>
                  </div>
                  <div class="information-box-content-wrapper">
                    <div class="information-box-container">
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-1.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data">
                                  <strong class="info-num">{{ dataSingleSite.saving_summary?.today_expense ? convertBahtCurrency(dataSingleSite.saving_summary.today_expense) : '0' }}</strong>
                                  <span class="info-unit-more">{{ dataSingleSite.saving_summary?.today_expense ? convertCountingUnit(dataSingleSite.saving_summary.today_expense) : '' }}</span>
                                  <span class="info-currency">
                                    {{ "\u0e3f" }}
                                  </span>
                                </div>
                                <p class="info-total">Today Expense</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-2.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data">
                                  <strong class="info-num">{{ dataSingleSite.saving_summary?.today_saving ? convertBahtCurrency(dataSingleSite.saving_summary.today_saving) : '0' }}</strong>
                                  <span class="info-unit-more">{{ dataSingleSite.saving_summary?.today_saving ? convertCountingUnit(dataSingleSite.saving_summary.today_saving) : '' }}</span>
                                  <span class="info-currency">
                                    {{ "\u0e3f" }}
                                  </span>
                                </div>
                                <p class="info-total">Today Saving</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-3.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data">
                                  <strong class="info-num">{{ dataSingleSite.saving_summary?.lifetime_saving ? convertBahtCurrency(dataSingleSite.saving_summary.lifetime_saving) : '0' }}</strong>
                                  <span class="info-unit-more">{{ dataSingleSite.saving_summary?.lifetime_saving ? convertCountingUnit(dataSingleSite.saving_summary.lifetime_saving) : '' }}</span>
                                  <span class="info-currency">
                                    {{ "\u0e3f" }}
                                  </span>
                                </div>
                                <p class="info-total">Lifetime Saving</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-4.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data info-data-green">
                                  <template v-if="dataSingleSite.saving_summary?.electricity_trend !== null && dataSingleSite.saving_summary?.electricity_percent !== null">
                                    <span class="down-arrow">
                                      <img v-if="dataSingleSite.saving_summary.electricity_trend === 'increment'" src="@/assets/pages/single-site/arrow-up-red.svg" />
                                      <img v-else src="@/assets/pages/single-site/arrow-down-green.svg" />
                                    </span>
                                    <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.saving_summary.electricity_percent) }}</strong>
                                    <span class="percent-text">%</span>
                                  </template>
                                  <strong v-else class="info-num">N/A</strong>
                                </div>
                                <p class="info-total">Current Electricity Trend</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-5.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data">
                                  <strong class="info-num">{{ dataSingleSite.saving_summary?.today_co2_saving ? convertCo2Value(dataSingleSite.saving_summary.today_co2_saving) : '0' }}</strong>
                                  <span class="info-unit">{{ dataSingleSite.saving_summary?.today_co2_saving ? convertCo2Unit(dataSingleSite.saving_summary.today_co2_saving) : 'kg' }}</span>
                                </div>
                                <p class="info-total">Today CO2 Saving</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-6.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data">
                                  <strong class="info-num">{{ dataSingleSite.saving_summary?.lifetime_co2_saving ? convertCo2Value(dataSingleSite.saving_summary.lifetime_co2_saving) : '0' }}</strong>
                                  <span class="info-unit">{{ dataSingleSite.saving_summary?.lifetime_co2_saving ? convertCo2Unit(dataSingleSite.saving_summary.lifetime_co2_saving) : 'kg' }}</span>
                                </div>
                                <p class="info-total">Lifetime CO2 Saving</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="single-site-general single-site-general--space">
          <div class="col-12">
            <div class="row">
              <div class="col-4 customize-col-4 customize--left">
                <div class="total-sites-chart total-sites-alarm total-sites-chart--background">
                  <div class="total-sites-chart-heading">
                    <h2 class="total-sites-chart--title">ALARM SUMMARY</h2>
                  </div>
                  <div class="col-12 total-sites-chart-content total-sites-chart-content--padding-alarm">
                    <div class="row">
                      <div class="col-6 p-0 col-6-left-chart">
                        <div class="donut-chart">
                          <VueApexCharts
                            v-if="series.length > 0" 
                            type="donut" 
                            :options="chartOptions" 
                            :series="series"
                          />
                          <div class="total-sites-title">
                            <strong class="title-desc-num">{{ dataSingleSite.alarm_summary.total }}</strong>
                            <p v-if="series.length > 0" class="title-desc-name">Active Alarm</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 p-0 col-6-right-chart">
                        <div class="alarm-summary-legend">
                          <div class="alarm-summary-legend-left">
                            <div class="sites-legend">
                              <span class="circle green-circle"></span>
                              <div class="sites-legend-desc">
                                <strong class="legend-desc-num">{{ dataSingleSite.alarm_summary.count.critical }}</strong>
                                <p class="legend-desc-status">Critical</p>
                              </div>
                            </div>
                            <div class="sites-legend">
                              <span class="circle yellow-circle"></span>
                              <div class="sites-legend-desc">
                                <strong class="legend-desc-num">{{ dataSingleSite.alarm_summary.count.major }}</strong>
                                <p class="legend-desc-status">Major</p>
                              </div>
                            </div>
                          </div>
                          <div class="alarm-summary-legend-right">
                            <div class="sites-legend">
                              <span class="circle grey-circle"></span>
                              <div class="sites-legend-desc">
                                <strong class="legend-desc-num">{{ dataSingleSite.alarm_summary.count.minor }}</strong>
                                <p class="legend-desc-status">Minor</p>
                              </div>
                            </div>
                            <div class="sites-legend">
                              <span class="circle circle-normal"></span>
                              <div class="sites-legend-desc">
                                <strong class="legend-desc-num" style="visibility: hidden">0</strong>
                                <p class="legend-desc-status">Normal</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="total-sites-chart total-sites-energy total-sites-chart--background">
                  <div class="total-sites-chart-heading">
                    <h2 class="total-sites-chart--title">ENERGY SUMMARY</h2>
                  </div>
                  <div class="total-sites-chart-content total-sites-chart-content--padding-energy">
                    <div class="total-saving-info-group total-saving-info--space">
                      <div class="info-img">
                        <img src="@/assets/pages/single-site/energy-1.2.svg" />
                      </div>
                      <div class="info-desc">
                        <div class="info-data">
                          <strong class="info-num">{{ convertEnergyValue(dataSingleSite.energy_summary.today_pv_energy) }}</strong>
                          <span class="info-unit">{{ convertEnergyUnit(dataSingleSite.energy_summary.today_pv_energy) }}</span>
                        </div>
                        <p class="info-total">Today</p>
                      </div>
                      <div class="info-desc">
                        <div class="info-data">
                          <strong class="info-num">{{ convertEnergyValue(dataSingleSite.energy_summary.lifetime_pv_energy) }}</strong>
                          <span class="info-unit">{{ convertEnergyUnit(dataSingleSite.energy_summary.lifetime_pv_energy) }}</span>
                        </div>
                        <p class="info-total">Lifetime</p>
                      </div>
                    </div>
                    <div class="total-saving-info-group">
                      <div class="info-img">
                        <img src="@/assets/pages/single-site/energy-2.2.svg" />
                      </div>
                      <div class="info-desc">
                        <div class="info-data">
                          <strong class="info-num">{{ convertEnergyValue(dataSingleSite.energy_summary.today_consumed_energy) }}</strong>
                          <span class="info-unit">{{ convertEnergyUnit(dataSingleSite.energy_summary.today_consumed_energy) }}</span>
                        </div>
                        <p class="info-total">Today</p>
                      </div>
                      <div class="info-desc">
                        <div class="info-data">
                          <strong class="info-num">{{ convertEnergyValue(dataSingleSite.energy_summary.lifetime_consumed_energy) }}</strong>
                          <span class="info-unit">{{ convertEnergyUnit(dataSingleSite.energy_summary.lifetime_consumed_energy) }}</span>
                        </div>
                        <p class="info-total">Lifetime</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8 customize-col-8 customize--right border-radius-8 total-sites-chart--background">
                <div class="site-monitoring-wrapper">
                  <div class="site-monitoring-heading">
                    <h2 class="site-monitoring-heading--title">SITE MONITORING</h2>
                  </div>
                  <div class="site-monitoring-house" :style="`background-image: url('${dataSingleSite.site_image ? dataSingleSite.site_image : ''}')`">
                    <div class="high-overlay row-overlay">
                      <div class="overlay-box">
                        <div class="info-desc">
                          <div class="info-data">
                            <template v-if="dataSingleSite?.site_monitoring?.house_load_power?.energy !== null && dataSingleSite?.site_monitoring?.house_load_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.house_load_power?.status)">
                              <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.site_monitoring.house_load_power.energy) }}</strong>
                              <span class="info-unit">kW</span>
                            </template>
                            <template v-else>
                              <strong class="info-num">−−</strong>
                            </template>
                          </div>
                          <p class="info-total">Home Load</p>
                        </div>
                      </div>
                      <div class="overlay-box" :style="`${dataSingleSite?.site_monitoring?.pv_power ? '' : 'visibility: hidden'}`">
                        <div class="info-desc">
                          <div class="info-data">
                            <template v-if="dataSingleSite?.site_monitoring?.pv_power?.energy !== null && dataSingleSite?.site_monitoring?.pv_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status)">
                              <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.site_monitoring.pv_power.energy) }}</strong>
                              <span class="info-unit">kW</span>
                            </template>
                            <template v-else>
                              <strong class="info-num">−−</strong>
                            </template>
                          </div>
                          <p class="info-total">PV &gt;</p>
                        </div>
                      </div>
                      <div class="overlay-box">
                        <div class="info-desc">
                          <div class="info-data">
                            <template v-if="dataSingleSite?.site_monitoring?.tree !== null && dataSingleSite?.site_monitoring?.tree !== undefined">
                              <strong class="info-num">{{ formatNumber(dataSingleSite.site_monitoring.tree) }}</strong>
                              <span class="info-unit">Trees</span>
                            </template>
                            <template v-else>
                              <strong class="info-num">−−</strong>
                            </template>
                          </div>
                          <p class="info-total">Planted Equivalently</p>
                        </div>
                      </div>
                    </div>
                    <div class="low-overlay row-overlay">
                      <div class="overlay-box" :style="`${dataSingleSite?.site_monitoring?.evse_power ? '' : 'visibility: hidden'}`">
                        <div class="info-desc">
                          <div class="info-data">
                            <template v-if="dataSingleSite?.site_monitoring?.evse_power?.energy !== null && dataSingleSite?.site_monitoring?.evse_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.evse_power?.status)">
                              <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.site_monitoring.evse_power.energy) }}</strong>
                              <span class="info-unit">kW</span>
                            </template>
                            <template v-else>
                              <strong class="info-num">−−</strong>
                            </template>
                          </div>
                          <p class="info-total">EV Charge &gt;</p>
                        </div>
                      </div>
                      <div class="overlay-box" :style="`${dataSingleSite?.site_monitoring?.bess_power ? '' : 'visibility: hidden'}`">
                        <div class="info-desc">
                          <div class="info-data">
                            <template v-if="dataSingleSite?.site_monitoring?.bess_power?.energy !== null && dataSingleSite?.site_monitoring?.bess_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.bess_power?.status)">
                              <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.site_monitoring.bess_power.energy) }}</strong>
                              <span class="info-unit">kW</span>
                            </template>
                            <template v-else>
                              <strong class="info-num">−−</strong>
                            </template>
                          </div>
                          <p class="info-total">Battery &gt;</p>
                        </div>
                      </div>
                      <div class="overlay-box">
                        <div class="info-desc">
                          <div class="info-data">
                            <template v-if="dataSingleSite?.site_monitoring?.grid_power?.energy !== null && dataSingleSite?.site_monitoring?.grid_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.grid_power?.status)">
                              <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.site_monitoring.grid_power.energy) }}</strong>
                              <span class="info-unit">kW</span>
                            </template>
                            <template v-else>
                              <strong class="info-num">−−</strong>
                            </template>
                          </div>
                          <p class="info-total">Grid &gt;</p>
                        </div>
                      </div>
                    </div>
                    <template v-if="dataSingleSite?.pv_installed">
                      <!-- pv online and operating: -->
                      <div 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) && dataSingleSite?.site_monitoring?.pv_power?.energy !== 0"
                        class="house-product house-product-pv-on"
                      >
                      </div>
                      <!-- pv online and idle: -->
                      <div
                        v-else-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) && dataSingleSite?.site_monitoring?.pv_power?.energy === 0" 
                        class="house-product house-product-pv-idle-online"
                      >
                      </div>
                      <!-- pv online and error: -->
                      <div 
                        v-else-if="['alarm'].includes(dataSingleSite?.site_monitoring?.pv_power?.status)" 
                        class="house-product house-product-pv-error-online"
                      >
                      </div>
                      <!-- pv offline: -->
                      <div
                        v-else
                        class="house-product house-product-pv-off"
                      >
                      </div>
                    </template>
                    <template v-if="dataSingleSite?.evse_installed">
                      <!-- ev online and operating: -->
                      <div 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.evse_power?.status) && dataSingleSite?.site_monitoring?.evse_power?.energy !== 0"
                        class="house-product house-product-ev-on"
                      >
                      </div>
                      <!-- ev online and idle: -->
                      <div 
                        v-else-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.evse_power?.status) && dataSingleSite?.site_monitoring?.evse_power?.energy === 0"
                        class="house-product house-product-ev-idle-online"
                      >
                      </div>
                      <!-- ev online and error: -->
                      <div
                        v-else-if="['alarm'].includes(dataSingleSite?.site_monitoring?.evse_power?.status)" 
                        class="house-product house-product-ev-error-online"
                      >
                      </div>
                      <!-- ev offline: -->
                      <div 
                        v-else
                        class="house-product house-product-ev-off"
                      >
                      </div>
                    </template>
                    <template v-if="dataSingleSite?.bess_installed">
                      <!-- battery online and operating: -->
                      <div 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.bess_power?.status) && dataSingleSite?.site_monitoring?.bess_power?.energy !== 0"
                        class="house-product house-product-battery-on"
                      >
                      </div>
                      <!-- battery online and idle: -->
                      <div
                        v-else-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.bess_power?.status) && dataSingleSite?.site_monitoring?.bess_power?.energy === 0" 
                        class="house-product house-product-battery-idle-online"
                      >
                      </div>
                      <!-- battery online and error: -->
                      <div
                        v-else-if="['alarm'].includes(dataSingleSite?.site_monitoring?.bess_power?.status)" 
                        class="house-product house-product-battery-error-online"
                      >
                      </div>
                      <!-- battery offline: -->
                      <div 
                        v-else
                        class="house-product house-product-battery-off"
                      >
                      </div>
                    </template>
                    <template v-if="dataSingleSite?.pv_installed">
                      <!-- pv panel online and operating: -->
                      <div 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) && dataSingleSite?.site_monitoring?.pv_power?.energy !== 0"
                        class="house-product house-product-pv-panel-on"
                      >
                      </div>
                      <!-- pv panel online and idle: -->
                      <div 
                        v-else-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) && dataSingleSite?.site_monitoring?.pv_power?.energy === 0" 
                        class="house-product house-product-pv-panel-idle-online"
                      >
                      </div>
                      <!-- pv panel online and error: -->
                      <div
                        v-else-if="['alarm'].includes(dataSingleSite?.site_monitoring?.pv_power?.status)"  
                        class="house-product house-product-pv-panel-error-online"
                      >
                      </div>
                      <!-- pv panel offline: -->
                      <div 
                        v-else
                        class="house-product house-product-pv-panel-off"
                      >
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="@/assets/css/single-site-dashboard.scss"></style>

<script>
import VueApexCharts from "vue-apexcharts";
import VueWeatherComponent from 'vue-weather-component';
import { singleSiteService } from "@/services";
import moment from "moment";

export default {
  name: "",
  components: { VueApexCharts, VueWeatherComponent, },
  data() {
    return {
      isLoading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
        },
        tooltip: {
          enabled: true,
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '75%'
            }
          }
        },
        colors: ['#BE5108', '#C7930B', '#2D6CA4'],
        labels: ["Critical", "Major", "Minor"],
        stroke: {
          show: false
        }
      },
      dataSingleSite: null,
      currentDay: {
        icon: 'clear-day',
        temp: '18',
        currentSummary: 'Fog.',
        hourlySummary: '3.6 mm',
        windSpeed: 6
      },
      forecast: [{
          name: 'Tue',
          icon: 'partly-cloudy-day',
          highTemp: '37',
          lowTemp: '32'
      }, {
          name: 'Wed',
          icon: 'fog',
          highTemp: '34',
          lowTemp: '29'
      }, {
          name: 'Thu',
          icon: 'cloudy',
          highTemp: '34',
          lowTemp: '29'
      }, {
          name: 'Fri',
          icon: 'rain',
          highTemp: '33',
          lowTemp: '25'
      }, {
          name: 'Sat',
          icon: 'snow',
          highTemp: '-9',
          lowTemp: '-20'
      }],
      dataWeather: null,
      testDataWea: null,
      testCurrentWea: null,
      intervalGetData: null,
      socketData: null,
    };
  },
  computed: {},
  async created() {
    this.isLoading = true;
    await this.getDataSingleSite();
    await this.getDataWeather();
    this.handleListWeather();
    this.forecast = this.testDataWea;
    this.currentDay = this.testCurrentWea;
    this.drawingDonutChart();
    this.isLoading = false;
  },
  mounted() {
    this.getDataSingleSite();
    this.socketData = new WebSocket(this.WEB_SOCKET_URL);
    this.socketData.onmessage = (event) => {
      const socketEventData = JSON.parse(event.data);
      if(socketEventData.action === "get_single_site_monitoring_summary" && socketEventData.data.id == this.$store.state.siteId) {
        this.dataSingleSite = { ...this.dataSingleSite, ...socketEventData.data };
      }
      if (["get_energy_and_saving_single_site", "get_alarm_single_site"].includes(socketEventData.action) && socketEventData.data.site_id == this.$store.state.siteId) {
        this.dataSingleSite = { ...this.dataSingleSite, ...socketEventData.data };
      }
    };
  },
  destroyed() {
    clearInterval(this.intervalGetData);
  },
  methods: {
    async getDataSingleSite(){
      const singleId = this.$store.state.siteId ? this.$store.state.siteId : null;
      if(singleId) {
        await singleSiteService
          .getSingleSite(singleId)
          .then((res) => {
            if (res.status == 200 && res?.data?.data) {
              this.dataSingleSite = res.data.data;
            } else {
              throw res;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleListWeather() {
      if (this.dataWeather?.list) {
        let weatherList = this.dataWeather.list;
        let dailyWeatherData = {};
        let currentDate = moment();
        let currentDateStr = currentDate.format('YYYY-MM-DD');
        let forecastData = [];
        let dataCurrent = {};
        let daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        let sortedDays = daysOfWeek.slice(currentDate.day()).concat(daysOfWeek.slice(0, currentDate.day()));

        for (let i = 0; i < 7; i++) {
          let item = weatherList[i];
          let itemDate = moment(item.dt * 1000);
          let dayOfWeek = sortedDays[i];
          item.dayOfWeek = dayOfWeek;
          let itemDateStr = itemDate.format('YYYY-MM-DD');
          if (itemDateStr === currentDateStr) {
            if (!dailyWeatherData[currentDateStr]) {
              dailyWeatherData[currentDateStr] = item;
            } else {
              if (itemDate.hour() > moment(dailyWeatherData[currentDateStr].dt * 1000).hour()) {
                dailyWeatherData[currentDateStr] = item;
              }
            }
          } else {
            currentDate.add(1, 'day');
            currentDateStr = currentDate.format('YYYY-MM-DD');
            dailyWeatherData[currentDateStr] = item;
          }

          if (itemDateStr === currentDateStr) {
            if (!dataCurrent.icon) {
              dataCurrent.icon = this.WEATHER_ICONS[item.weather[0].icon];
            }
            if (!dataCurrent.description) {
              dataCurrent.description = item.weather[0].description;
            }
            if (!dataCurrent.currentSummary) {
              dataCurrent.currentSummary = (item.main.temp - 273.15).toFixed(0) + " °C";
            }
            if (!dataCurrent.hourlySummary && item['rain']) {
              dataCurrent.hourlySummary = item.rain['3h'] + " mm";
            }
            if (!dataCurrent.windSpeed && item['wind']) {
              dataCurrent.windSpeed = item.wind.speed;
            }
          }
          let highTempCelsius = (item.main.temp_max - 273.15).toFixed(0);
          let lowTempCelsius = (item.main.temp_min - 273.15).toFixed(0);
          forecastData.push({
            name: dayOfWeek,
            icon: this.WEATHER_ICONS[item.weather[0].icon],
            highTemp: highTempCelsius,
            lowTemp: lowTempCelsius,
          });
        }
        this.testDataWea = forecastData;
        this.testCurrentWea = dataCurrent;
      }
    },
    drawingDonutChart () {
      this.series = Object.values(this.dataSingleSite.alarm_summary.count).map(value => value);
      if (!this.series.some(element => element !== 0)) {
        this.series.push(1);
        this.chartOptions.tooltip.enabled = false;
        this.chartOptions.colors.push("#4A8521");
        this.chartOptions.labels.push("Normal");
      }
    },
    async getDataWeather () {
      let lat = parseFloat(this.dataSingleSite.latitude);
      let long = parseFloat(this.dataSingleSite.longitude);
      await singleSiteService
        .getDataWeather(lat, long)
        .then((res) => {
          if (res.status == 200 && res?.data) {
            this.dataWeather = res.data;
          } else {
            throw res;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeDestroy() {
    if (this.socketData) {
      this.socketData.close();
    }
  },
};
</script>
